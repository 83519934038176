<template>
  <div>
    <div slot="header" class="clearfix">
      <div style="margin-bottom: 10px">
        <el-button type="primary" plain @click="openAddDialog" style="width: 120px;">新增</el-button>
        <el-button type="danger" plain @click="delbatch" :loading="isloading2">批量删除</el-button>
<!--        <el-button type="info" plain @click="reset">刷新</el-button>-->

      </div>
      <div style="margin-bottom: 10px" v-if="isA" key="18">
        <el-autocomplete v-if="isA" key="1"
                         style="width: 200px;;padding-right: 10px"
                         v-model="username"
                         placeholder="输入或选择用户"
                         :fetch-suggestions="querySearch"
                         clearable
        ></el-autocomplete>
        <span > <el-button  type="primary" @click="load(1)" style="width: 100px;">查询</el-button></span>
        <span style="padding-left: 10px"> <el-button type="primary" plain @click="reset">重置</el-button> </span>

      </div>

<!--      <span class="yh">内容列表</span>-->
      <el-table  :data="contents" stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'}"
                style="width: 100%; padding-top: 10px"
                @selection-change="handleSelectionChange" key="1">

        <el-table-column   type="selection" width="50" align="center" key="2"></el-table-column>

        <el-table-column label="用户" :min-width="100" :show-overflow-tooltip="true"  align="center" v-if="isA" key="3">
          <template v-slot="scope">
            <span>{{ scope.row.username }}</span>
          </template>
        </el-table-column>

<!--        <el-table-column label="小程序路径" :min-width="100" :show-overflow-tooltip="true" align="center"  key="70">-->
<!--          <template v-slot="scope" >-->
<!--            <span @click="copyAddress(scope.row)" style="cursor: pointer;">{{ scope.row.page }}</span>-->
<!--&lt;!&ndash;            <el-button type="primary" size="mini" plain @click="copyAddress(scope.row)"  key="51">复制路径</el-button>&ndash;&gt;-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="备注" :min-width="120" :show-overflow-tooltip="true" align="center" key="71">
          <template v-slot="scope">
            <span @click="copyAddress2(scope.row)" style="cursor: pointer;">{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="广告控制" :min-width="90" :show-overflow-tooltip="true" align="center" key="72">
          <template v-slot="scope"> <span>{{ scope.row.sf === "1" ? '开启广告' : '关闭广告' }}</span> </template>
        </el-table-column>
        <el-table-column label="强点概率" :min-width="90" :show-overflow-tooltip="true" align="center" key="73">
          <template v-slot="scope">
            <span>{{ scope.row.qdgl }}</span>
          </template>
        </el-table-column>
        <el-table-column label="标题" :min-width="110" :show-overflow-tooltip="true" align="center" key="74">
          <template v-slot="scope">
            <span @click="copyAddress3(scope.row)" style="cursor: pointer;">{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="内容" :min-width="120" :show-overflow-tooltip="false" align="center" key="75">
          <template v-slot="scope">
            <span class="ellipsis-text">{{ scope.row.content }}</span>
          </template>
        </el-table-column>
        <el-table-column label="复制按钮" :min-width="90" :show-overflow-tooltip="true" align="center" key="76">

            <template v-slot="scope"> <span>{{ scope.row.copy === "1" ? '显示' : '不显示' }}</span> </template>

        </el-table-column>
        <el-table-column label="复制内容" :min-width="100" :show-overflow-tooltip="true" align="center" key="77">
          <template v-slot="scope">
            <span>{{ scope.row.copyContent }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" :min-width="270" align="center" key="10">
          <template v-slot="scope">
            <div style=" white-space: nowrap;">
              <el-button type="primary" size="mini" plain @click="copyAddress(scope.row)"  key="51">复制小程序路径</el-button>
            <el-button type="primary" size="mini" plain @click="edit(scope.row)"  key="11" >编辑</el-button>
            <el-button type="danger" size="mini" plain @click="handleDelete(scope.row.id)"  key="12">删除</el-button></div>
          </template>
        </el-table-column>
        <el-table-column label="创建日期" :min-width="130" :show-overflow-tooltip="true" align="center" key="24">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.createTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="更新日期" :min-width="130" :show-overflow-tooltip="true" align="center" key="25">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.updateTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>



      </el-table>

      <div style="display: flex;justify-content: center;margin-top: 20px" key="31">
        <el-pagination

            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total=total>
        </el-pagination>
      </div>

    </div>
    <div >
      <el-dialog title="新增内容" :visible.sync="formVisible"   width="70%" :close-on-click-modal="false"  @close="qx0"  :before-close="qx">
        <el-form :model="form" style="width: 99%"  >

          <el-form-item label="用户" :label-width="formLabelWidth" style="width: 35%" v-if="isA" >
            <el-autocomplete  class="inline-input"
                              v-model="form.username"
                              :fetch-suggestions="querySearch"
                              placeholder="输入或选择用户"
                              :value="form.username"
                              :trigger-on-focus="false"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="id" :label-width="formLabelWidth" style="width: 95%"  v-if="isA" >
            <el-input v-model="form.unid" autocomplete="off" :disabled="true"></el-input>
          </el-form-item>
<!--          <el-form-item label="小程序路径" :label-width="formLabelWidth" style="width: 95%; white-space: nowrap;"  >-->
<!--            <el-input v-model="form.page" autocomplete="off" :disabled="true"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="备注" :label-width="formLabelWidth" style="width: 95%">
            <el-input v-model="form.remark" autocomplete="off" placeholder="可以输入任意内容，用于区分推广情况"></el-input>
          </el-form-item>
          <el-form-item label="广告控制" :label-width="formLabelWidth" style="width: 95%">
            <el-select v-model="form.sf" placeholder="是否开启广告？">
              <el-option label="开启广告" :value="1"></el-option>
              <el-option label="关闭广告" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="强点概率" :label-width="formLabelWidth" style="width: 95%">
            <el-input type="number" step="0.01"  :min="0"
                      :max="1"   v-model="form.qdgl" autocomplete="off" placeholder="建议填写0到0.5之间的数，或者空置不填，由算法自动调整（推荐）"></el-input>
          </el-form-item>
          <el-form-item label="标题" :label-width="formLabelWidth" style="width: 95%">
            <el-input v-model="form.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="内容" :label-width="formLabelWidth" style="width: 95%">
            <div id="editor" style="min-height: 500px; width: 100% ;"></div>
          </el-form-item>

          <el-form-item label="复制按钮" :label-width="formLabelWidth" class="form-item-wide">
            <el-select v-model="form.copy" placeholder="是否显示复制按钮？">
              <el-option label="显示" :value="1"></el-option>
              <el-option label="不显示" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="复制内容" :label-width="formLabelWidth" style="width: 95%">
            <el-input v-model="form.copyContent" autocomplete="off" placeholder="如果选择显示复制按钮，那么此处填写需要粉丝复制的内容"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="display: flex; justify-content:center">
          <el-button @click="qx">取 消</el-button>
          <el-button type="primary" @click="handleAdd" :loading="isloading" style="width: 120px;">提 交</el-button>
        </div>
      </el-dialog>
    </div>

    <div >
      <el-dialog title="编辑内容" :visible.sync="formVisible2" width="70%" :close-on-click-modal="false"  @close="qx2">
        <el-form :model="form2" style="width: 99%;" >
          <el-form-item label="用户" :label-width="formLabelWidth" style="width: 35%" v-if="isA" >
            <el-input v-model="form2.username" autocomplete="off" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="id" :label-width="formLabelWidth" style="width: 95%"  v-if="isA" >
            <el-input v-model="form2.unid" autocomplete="off" :disabled="true"></el-input>
          </el-form-item>
<!--          <el-form-item label="小程序路径" :label-width="formLabelWidth" style="width: 95%; white-space: nowrap;"  >-->
<!--            <el-input v-model="form2.page" autocomplete="off" :disabled="true"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item label="备注" :label-width="formLabelWidth" style="width: 95%">
            <el-input v-model="form2.remark" autocomplete="off" placeholder="可以输入任意内容，用于区分推广情况"></el-input>
          </el-form-item>
          <el-form-item  label="广告控制" :label-width="formLabelWidth" style="width: 95%">
            <el-select v-model="form2.sf" placeholder="是否开启广告？" clearable>
              <el-option label="开启广告" :value="1"></el-option>
              <el-option label="关闭广告" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="强点概率" :label-width="formLabelWidth" style="width: 95%">
            <el-input type="number" step="0.01"  :min="0"
                      :max="1"   v-model="form2.qdgl" autocomplete="off" placeholder="自动调整"></el-input>
          </el-form-item>
          <el-form-item label="标题" :label-width="formLabelWidth" style="width: 95%">
            <el-input v-model="form2.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="内容" :label-width="formLabelWidth" style="width: 95%">
            <div id="editor2"></div>
          </el-form-item>
          <el-form-item label="复制按钮" :label-width="formLabelWidth" class="form-item-wide">
            <el-select v-model="form2.copy" placeholder="是否显示复制按钮？">
              <el-option label="显示" :value="1"></el-option>
              <el-option label="不显示" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="复制内容" :label-width="formLabelWidth" style="width: 95%">
            <el-input v-model="form2.copyContent" autocomplete="off" placeholder="如果选择显示复制按钮，那么此处填写需要粉丝复制的内容"></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer" style="display: flex; justify-content:center">
          <el-button @click="formVisible2 = false">取 消</el-button>
          <el-button type="primary" @click.stop="handleEdit" :loading="isloading1" style="width: 120px;" >保 存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import E from 'wangeditor';

export default {
  name: 'contents',
  data() {
    return {
      user:JSON.parse(localStorage.getItem('honey-user')||'{}'),
      editor:null,
      editor2:null,
      isloading:false,
      isloading1:false,
      isloading2:false,
      isA:null,
      contents: [],
      content:{},
      pageNum: 1,
      pageSize: 14,
      username:"",
      appid:'',
      projectName:"",
      total:0,
      formVisible: false,
      formVisible2: false,
      form: {
        username: "",
        sf:1// 输入框绑定的字段
      },
      form2: {},
      formLabelWidth: '80px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids:[],
      formUsers:[],
      restaurants: [],
      lu:[],
      currentUser:{},
      currentUserName:'',
      role1:2,
      title1:"",
      DayIncome:0,

    }
  },

  mounted() {
    this.loadUser()
    this.currentUser = JSON.parse(localStorage.getItem('honey-user'));
    this.currentUserName =this.currentUser.username;
    this.form.username = this.currentUserName;  // 默认设置当前用户
    this.role1 =this.currentUser.role;
    if (this.role1 === 1) {
      this.load()
      this.isA = true

    }else {
      this.load2()
    }
    this.loadUan1()
  },
  methods: {

    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },
    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },

    createpageunid(){
// 获取当前时间的时间戳
      var timestamp = new Date().getTime();

      // 获取时间戳的最后6位数字
      var timestampStr = timestamp.toString().slice(-7);

      // 将这些数字转成字母
      var timestampLetters = timestampStr.split('').map(num => String.fromCharCode(65 + parseInt(num) % 26)).join('');

      // 生成一个随机字母
      var randomLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));

      // 拼接成7个字母
      this.form.unid = timestampLetters + randomLetter;

    },
    openAddDialog() {
      this.formVisible = true;
      this.createpageunid();

      this.form.page = "pages/pic6/pic6.html?id=" + this.form.unid;
      this.qx0()
      this.$nextTick(() => {
        this.editor = new E('#editor');
        this.editor.config.placeholder = '第一张图会成为封面图，建议设置为居中显示';  // 设置富文本编辑器的提示文本

        // 配置图片上传
        this.editor.config.uploadImgServer = this.$baseUrl+'/contents/editor/upload'; // 上传图片的后端接口
        this.editor.config.uploadImgMaxSize = 0.8 * 1024 * 1024;  // 图片最大尺寸 0.8MB
        this.editor.config.uploadImgMaxLength = 20; // 上传的最大图片数量
        // 自定义图片上传回调
        this.editor.config.uploadFileName = 'file';
        this.editor.config.uploadImgHeaders ={
          token:this.user.token
        }
        this.editor.create();
        // 修改内联样式，确保容器高度生效
        const editorContainer = document.querySelector('.w-e-text-container');
        editorContainer.style.height = '600px';  // 修改编辑器容器的高度
        editorContainer.style.minHeight = '600px';  // 修改最小高度
      });

    },

    handleAdd(){

      if (this.form.sf === undefined || this.form.sf === "") {
        this.isloading = false;
        this.$message.error('请设置广告控制选项');  // 提示用户选择广告控制
        return;  // 中止提交
      }
      this.isloading=true
      const usernameToSubmit = (this.form.username || "").trim() === ""
          ? this.currentUserName
          : this.form.username;
      this.form.username = usernameToSubmit;
      let co =this.editor.txt.html()
      this.form.content = co
      this.content = this.form;
      this.$request.post('/contents/add', this.content).then((res3) => {
        if (res3.code === '200') {
          this.isloading=false
          this.$message.success('创建成功！');
          this.formVisible = false; // 关闭模态框
          this.reset()
          this.qx0()
        }else {
          this.$message.error(res3.msg)
          this.isloading=false
          console.error(res3);
          // this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(error => {
        this.isloading=false
        // this.hideLoading(); // 隐藏加载弹窗
        console.error(error); // 处理错误情况
        this.$message.error(res3.msg)
      });
      this.form.username = "";

    },

    edit(row){
      this.form2 = JSON.parse(JSON.stringify(row));
      this.form2.sf =Number(this.form2.sf)
      this.form2.copy =Number(this.form2.copy)
      this.qx2()
      this.formVisible2=true; // 打开模态框
      this.$nextTick(() => {
          this.editor2 = new E('#editor2');
        this.editor2.config.uploadImgServer = this.$baseUrl+'/contents/editor/upload'; // 上传图片的后端接口
        this.editor2.config.uploadImgMaxSize = 0.8 * 1024 * 1024;  // 图片最大尺寸 0.8MB
        this.editor2.config.uploadImgMaxLength = 20; // 上传的最大图片数量
        // 自定义图片上传回调
        this.editor2.config.uploadFileName = 'file';
        this.editor2.config.uploadImgHeaders ={
          token:this.user.token
        }
          this.editor2.create();
        // 修改内联样式，确保容器高度生效
        const editorContainer = document.querySelector('.w-e-text-container');
        editorContainer.style.height = '600px';  // 修改编辑器容器的高度
        editorContainer.style.minHeight = '600px';  // 修改最小高度
        this.editor2.txt.html(row.content);
      })
    // this.qx0()

    },
    handleEdit(){
      this.isloading1=true
      let co2 =this.editor2.txt.html()
      this.form2.content = co2

      this.content = this.form2;
      // this.showLoading(); // 显示加载弹窗
      this.$request.put('/contents/update', this.content).then((res7) => {
        if (res7.code === '200') {
          this.$message.success('修改成功！');
          // this.showLoading()
          this.isloading1=false
          this.formVisible2 = false; // 关闭模态框
          this.qx2()
          this.reset()
        }else {
          this.$message.error(res7.msg)
        }
      }).catch(error => {
        console.error(error); // 处理错误情况
      });

    },
    qx0(){
      if(this.editor){
        this.editor.destroy();  // 销毁编辑器
        this.editor = null;     // 清空编辑器引用
      }


    },
    qx2(){
      if(this.editor2){
        this.editor2.destroy();  // 销毁编辑器
        this.editor2 = null;     // 清空编辑器引用
      }

    },
    qx(){
      this.formVisible = false
      this.username = "";
      this.projectName ="";
      this.appid=''
      this.form={};

    },
    loadUan1() {
      this.getD().then(() => {
        return this.$request.get('/uannounce/selectAll');
      }).then((result) => {
        this.lu = result.data;
        if (this.lu) {
          this.formUan1 = this.lu.map(lu => ({
                utitle: lu.utitle,
                ucontent: lu.ucontent,
                id: lu.id,
                urole: lu.urole,
                vis: lu.vis
              })
          );
        }

        const targetItem1 = this.formUan1.find(item => item.id === 1);
        const targetItem2 = this.formUan1.find(item => item.id === 2);
        const targetItem3 = this.formUan1.find(item => item.id === 3);
        if(this.DayIncome1 !==0){
          this.title1 = targetItem3.ucontent;
        }
        if(this.DayIncome1===0){
          const now = new Date();
          const hours = now.getHours();
          const targetItem3 = this.formUan1.find(item => item.id === 3);
          if(targetItem3){
            if(targetItem3.vis === 1 && hours> 19 ){

              this.title1 = targetItem3.ucontent;
            }else{
              if (hours> 19){
                this.title1 = targetItem1.ucontent;

              }else {
                this.title1 = targetItem2.ucontent;
              }
            }
          }


        }
        this.$emit('update:title1', this.title1);
      });
    },
    getD() {
      return this.$request.get('/udata/DayselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data) {
          this.DayIncome1 = res.data.DayIncome;
          // console.log("this.DayIncome1 in getD:");
          // console.log(this.DayIncome1);
        } else {
          console.log(res);
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
      });
    },

    copyAddress(row) {
      const address = row.page;
      const textArea = document.createElement('textarea');
      textArea.value = address;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      this.$message.success('小程序路径已复制！');
    },
    copyAddress2(row) {
      const address = row.remark;
      const textArea = document.createElement('textarea');
      textArea.value = address;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      this.$message.success('备注已复制！');
    },
    copyAddress3(row) {
      const address = row.title;
      const textArea = document.createElement('textarea');
      textArea.value = address;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      this.$message.success('标题已复制！');
    },
    delbatch(){
      if(!this.ids.length){
        this.$message.warning('请选择要删除的数据')
        return
      }

      this.$confirm('是否要批量删除?','确认删除',{type:"warning"}).then(response => {
        this.isloading2=true
        // this.showLoading(); // 显示加载弹窗
        this.$request.delete('/contents/delete/batch',{data:this.ids}).then((res1) => {
          if (res1.code === '200') {
            this.$message.success('删除成功！');
            this.isloading2=false
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            // this.hideLoading(); // 隐藏加载弹窗
            if (this.role1 === 1) {
              this.load()
              // console.log('this begin load')
              this.isA = true

            }else {
              this.load2()
            }
          }else {
            this.$message.error(res1.mag)
            this.isloading2=false
            // this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => {})
      // this.hideLoading(); // 隐藏加载弹窗
    },
    handleDelete(id){
      // console.log('Deleting content with ID:', id);
      this.$confirm('是否要删除?','确认删除',{type:"warning"}).then(response => {
        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/contents/delete/'+id).then((res2) => {

          if (res2.code === '200') {
            this.$message.success('删除成功！');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.hideLoading(); // 隐藏加载弹窗
            if (this.role1 === 1) {
              this.load()
              // console.log('this begin load')
              this.isA = true

            }else {
              this.load2()
            }
          }else {
            this.$message.error(res2.mag)
            this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => {})
    },
    querySearch(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.formUsers.filter(this.createFilter(queryString)) : this.formUsers;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadUser(){

      this.$request.get('/user/selectAll').then((result) => {
        this.lu = result.data
        this.formUsers = this.lu.map(lu=> ({ value: lu.username}));
        this.hideLoading(); // 隐藏加载弹窗
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.$router.push('/login'); // 可能需要在请求失败时重定向

      });
    },
    load2(pageNum) {
      this.showLoading(); // 显示加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/contents/selectByPage1', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.currentUserName,
          appid: this.appid,
          projectName:this.projectName,
          h5: 0 // 传递 h5 = 0，表示查询 h5 字段为空的记录
        }
      }).then(res4 => {
        if (res4.data && res4.data.records) {
          this.contents = res4.data.records;
          this.total = res4.data.total;
          // console.log('this.contents is:')
          // console.log(this.contents)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.contents = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    load(pageNum) {
      this.showLoading(); // 显示加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }

      this.$request.get('/contents/selectByPage1', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.username,
          appid: this.appid,
          projectName:this.projectName,
          h5: 0 // 传递 h5 = 0，表示查询 h5 字段为空的记录
        }
      }).then(res5 => {
        if (res5.data && res5.data.records) {
          this.contents = res5.data.records;
          this.total = res5.data.total;

          // console.log('this.contents is:')
          // console.log(this.contents)
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.hideLoading(); // 隐藏加载弹窗
          this.contents = [];
          this.total = 0; // 或者其他处理逻辑
        }
      }).catch(err2 => {
        this.hideLoading(); // 隐藏加载弹窗
        console.error(err2); // 处理错误
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      if (this.role1 === 1) {
        this.load()
        // console.log('this begin load')
        this.isA = true

      }else {
        this.load2()

        this.isA = null

      }
    },
    handleSelectionChange(rows){
      this.ids = rows.map(item => item.id);
    },

    reset(){
      this.username = "";
      this.projectName ="";
      // this.form2={};
      this. appid=''
      this.form={};
      if (this.role1 === 1) {
        this.load(1)
        // console.log('this begin load')
        this.isA = true

      }else {
        this.load2(1)

        this.isA = null

      }
    },

  }
}
</script>

<style scoped>
.w-e-text {
  height: 100% !important; /* 确保占满父容器的高度 */
  min-height: 100% !important; /* 确保最小高度占满父容器 */
}
.w-e-text-container {
  overflow: hidden;
  height: 600px !important;
  min-height: 600px !important; /* 确保最小高度也调整 */
}

.ellipsis-text {
  display: block;
  white-space: nowrap;      /* 不换行 */
  overflow: hidden;         /* 超出部分隐藏 */
  text-overflow: ellipsis;  /* 超出部分显示省略号 */
  max-width: 130px;         /* 设置最大宽度 */
}
.el-input__inner{
  width: 80% !important;
}
.form-item-wide { width: 80% !important; /* 使用 !important 来覆盖默认样式 */ }
.el-table .el-table__row {
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}


</style>
