<template>
  <div>
    <div slot="header" class="clearfix">
      <div style="">
        <div style="margin-bottom: 10px">
          <el-button type="danger" plain @click="delbatch2" :loading="isloading1">清空日志</el-button>
          <el-button type="danger" plain @click="delbatch" :loading="isloading2">批量删除</el-button>
<!--          <el-button type="info" plain @click="reset">刷新</el-button>-->
        </div>
<!--        <el-input style="width: 200px;" placeholder="模块" v-model="operation"></el-input>-->
<!--        <el-input style="width: 200px; margin: 0 10px" placeholder="类型" v-model="type"></el-input>-->
        <el-select v-model="operation" placeholder="模块" style="width: 200px;margin-right: 10px">
          <el-option label="登录" value="登录"></el-option>
          <el-option label="注册" value="注册"></el-option>
          <el-option label="账户信息" value="账户信息"></el-option>
          <el-option label="用户管理" value="用户管理"></el-option>
          <el-option label="收益数据" value="收益数据"></el-option>
          <el-option label="内容管理" value="内容管理"></el-option>
          <el-option label="项目信息" value="项目信息"></el-option>
          <el-option label="公告管理" value="公告管理"></el-option>
          <el-option label="操作日志" value="操作日志"></el-option>
        </el-select>

        <el-select v-model="type" placeholder="类型" style="width: 200px; margin-right: 10px">
          <el-option label="注册" value="注册"></el-option>
          <el-option label="登录" value="登录"></el-option>
          <el-option label="新增" value="新增"></el-option>
          <el-option label="修改" value="修改"></el-option>
          <el-option label="删除" value="删除"></el-option>
          <el-option label="批量删除" value="批量删除"></el-option>

        </el-select>

        <el-button type="primary" @click="load(1)" :loading="isloading" style="width: 100px;">查询</el-button>
        <el-button type="primary" plain @click="reset">重置</el-button>

      </div>
<!--      <span class="yh">日志列表</span>-->
      <el-table :data="logs" stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'}"
                style="width: 100%; padding-top: 10px;overflow-x: hidden"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" :min-width="80" align="center"></el-table-column>
<!--        <el-table-column prop="id" label="序号" align="center" :min-width="120"></el-table-column>-->
        <el-table-column prop="user" label="操作人" align="center" :min-width="120"></el-table-column>
        <el-table-column prop="operation" label="模块" align="center" :min-width="120"></el-table-column>
        <el-table-column prop="type" label="操作类型" align="center" :min-width="120"></el-table-column>
        <el-table-column prop="ip" label="ip地址" align="center" :min-width="120"></el-table-column>
        <el-table-column prop="time" label="日期" align="center" :min-width="120"></el-table-column>


        <el-table-column label="操作" min-width="170" align="center">
          <template v-slot="scope">
            <el-button type="danger"   size="mini" plain @click="del(scope.row.id)" >删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <div style="display: flex;justify-content: center;margin-top: 20px">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total=total>
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import axios from "axios";
import { Loading } from 'element-ui';
export default {
  name: 'logs',
  data() {
    return {
      isloading: false,
      isloading1: false,
      isloading2: false,
      isloading3: false,
      logs: [],
      user: { name: "" },
      pageNum: 1,
      pageSize: 14,
      username:"",
      name:"",
      operation:"",
      type:"",
      total:0,
      formVisible: false,
      formVisible2: false,
      form: {
      },
      form2: {
      },
      formLabelWidth: '80px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids:[]
    }
  },
  mounted() {
    this.load()
  },

  methods: {
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    delbatch2(){

      this.$confirm('是否要清空日志?','确认清空',{type:"warning"}).then(response => {
        this.isloading1=true
        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/logs/delete/all').then((res) => {
          if (res.code === '200') {
            this.$message.success('清空成功');
            this.isloading1=false
            this.hideLoading(); // 隐藏加载弹窗

            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.load()
          }else {
            this.$message.error(res.mag)
            this.isloading1=false
            this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => {  this.isloading1=false;this.hideLoading()})
    },
    delbatch(){
      if(!this.ids.length){
        this.$message.warning('请选择要删除的数据')
        return
      }
      this.$confirm('是否要批量删除?','确认删除',{type:"warning"}).then(response => {
        this.showLoading(); // 显示加载弹窗
        this.isloading2=true
        this.$request.delete('/logs/delete/batch',{data:this.ids}).then((res) => {
          if (res.code === '200') {
            this.isloading2=false
            this.hideLoading(); // 隐藏加载弹窗
            this.$message.success('删除成功');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.load()
          }else {
            this.$message.error(res.mag)
            this.isloading2=false
            this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => { this.hideLoading();this.isloading2=false}
      )

    },
    handleSelectionChange(rows){
      this.ids = rows.map(item => item.id);
    },
    del(id){
      console.log('Deleting user with ID:', id);
      this.$confirm('是否要删除?','确认删除',{type:"warning"}).then(response => {

        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/logs/delete/'+id).then((res) => {
          if (res.code === '200') {

            this.hideLoading(); // 隐藏加载弹窗
            this.$message.success('删除成功');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.load()
          }else {

            this.hideLoading(); // 隐藏加载弹窗
            this.$message.error(res.mag)
          }
        })
      }).catch((error) => {  this.hideLoading()})

    },

    reset(){
      this.username = "";
      this.name ="";
      this.form2={};
      this.form={};
      this.operation="";
      this. type="",
      this.load(1)
    },
    load(pageNum) {
      this.isloading=true
      this.showLoading(); // 显示加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }
      this.$request.get('/logs/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          operation: this.operation,
          type:this.type
        }
      }).then(res => {
        if (res.data && res.data.records) {
          this.logs = res.data.records;
          this.total = res.data.total;
          this.isloading=false
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.logs = [];
          this.total = 0; // 或者其他处理逻辑
          this.isloading=false
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err => {
        console.error(err); // 处理错误
        this.isloading=false
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load()
    },
  }
}
</script>

<style scoped>
.el-table .el-table__row {
  width: 100%;
  overflow-x: hidden !important; /* 隐藏横向滚动条 */
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}
</style>
