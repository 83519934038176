<template>
  <div>
<!--    <span class="yh">账户信息</span>-->
    <el-card :style="{ width: '40%', minWidth: '750px' }" class="large-font0" style="margin-top: 20px">
      <el-descriptions :column="1" v-if="!isEditing" class="large-font1">
        <el-descriptions-item label="用户名" class="large-font">{{ user.username }}</el-descriptions-item>
        <el-descriptions-item label="昵称" class="large-font">{{ user.name }}</el-descriptions-item>
        <el-descriptions-item label="用户类型" class="large-font">{{ user.usertype }}</el-descriptions-item>
        <el-descriptions-item label="微信号" class="large-font">{{ user.wechat }}</el-descriptions-item>
        <el-descriptions-item label="姓名" class="large-font">{{ user.connect }}</el-descriptions-item>
        <el-descriptions-item label="联系电话" class="large-font">{{ user.phone }}</el-descriptions-item>
        <el-descriptions-item label="银行" class="large-font">{{ user.bank }}</el-descriptions-item>
        <el-descriptions-item label="支行名称" class="large-font">{{ user.bankName }}</el-descriptions-item>
        <el-descriptions-item label="银行卡号" class="large-font">{{ user.bankNumber }}</el-descriptions-item>
        <el-descriptions-item label="支行地址" class="large-font">{{ user.address }}</el-descriptions-item>
        <el-descriptions-item label="运营方式" class="large-font">{{ user.channel }}</el-descriptions-item>
        <el-descriptions-item label="邮箱" class="large-font">{{ user.email }}</el-descriptions-item>
      </el-descriptions>

      <el-form v-else :model="user" label-width="90px" style="padding-right: 30px">
        <el-form-item label="用户名" prop="username" style="font-size: large">
          <el-input v-model="user.username"  style="font-size: medium" disabled></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="name" style="font-size: medium">
          <el-input v-model="user.name"  style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="name" style="font-size: medium" >
          <el-input v-model="user.usertype"  style="font-size: medium" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name" style="font-size: medium">
          <el-input v-model="user.connect" placeholder="姓名应与提现银行卡实名一致" style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="微信号" prop="name" style="font-size: medium">
          <el-input v-model="user.wechat" placeholder="" style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="name" style="font-size: medium">
          <el-input v-model="user.phone"  style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="银行" prop="name" style="font-size: medium">
          <el-input v-model="user.bank"  style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="支行名称" prop="name" style="font-size: medium">
          <el-input v-model="user.bankName"  style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号" prop="name" style="font-size: medium">
          <el-input v-model="user.bankNumber"  style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="支行地址" prop="name" style="font-size: medium">
          <el-input v-model="user.address"  style="font-size: medium"></el-input>
        </el-form-item>

        <el-form-item label="运营方式" prop="name" style="font-size: medium">
          <el-input v-model="user.channel" placeholder="公众号/微信群/朋友圈/微博/抖音" style="font-size: medium"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="name" style="font-size: medium">
          <el-input v-model="user.email" placeholder="联系邮箱" style="font-size: medium"></el-input>
        </el-form-item>
      </el-form>

      <div class="button-container" style="padding-right: 30px">
        <el-button v-if="isEditing" type="default" @click="cancelEditing">取 消</el-button>
        <el-button v-if="isEditing" type="primary" @click="update">保 存</el-button>
        <el-button v-if="!isEditing" type="primary" @click="startEditing">修 改 信 息</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem('honey-user') || '{}'),
      isEditing: false,
      originalUser: {}
    };
  },
  mounted() {
    this.loadUan1()
  },
  methods: {
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    loadUan1() {
      this.getD().then(() => {
        return this.$request.get('/uannounce/selectAll');
      }).then((result) => {
        this.lu = result.data;
        if (this.lu) {
          this.formUan1 = this.lu.map(lu => ({
                utitle: lu.utitle,
                ucontent: lu.ucontent,
                id: lu.id,
                urole: lu.urole,
                vis: lu.vis
              })
          );
        }

        const targetItem1 = this.formUan1.find(item => item.id === 1);
        const targetItem2 = this.formUan1.find(item => item.id === 2);
        const targetItem3 = this.formUan1.find(item => item.id === 3);
        if(this.DayIncome1 !==0){
          this.title1 = targetItem3.ucontent;
        }
        if(this.DayIncome1===0){
          const now = new Date();
          const hours = now.getHours();
          const targetItem3 = this.formUan1.find(item => item.id === 3);
          if(targetItem3){
            if(targetItem3.vis === 1 && hours> 19 ){

              this.title1 = targetItem3.ucontent;
            }else{
              if (hours> 19){
                this.title1 = targetItem1.ucontent;

              }else {
                this.title1 = targetItem2.ucontent;
              }
            }
          }


        }
        this.$emit('update:title1', this.title1);
      });
    },
    getD() {
      return this.$request.get('/udata/DayselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data) {
          this.DayIncome1 = res.data.DayIncome;
          // console.log("this.DayIncome1 in getD:");
          // console.log(this.DayIncome1);
        } else {
          console.log(res);
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
      });
    },
    startEditing() {
      this.isEditing = true;
      this.originalUser = JSON.parse(JSON.stringify(this.user));
    },
    cancelEditing() {
      this.user = JSON.parse(JSON.stringify(this.originalUser));
      this.isEditing = false;
    },
    update() {
      // console.log("当前的user:", this.user);
      this.showLoading(); // 显示加载弹窗
      this.$request.put('/user/update', this.user).then((res) => {
        if (res.code === '200') {
          this.hideLoading(); // 隐藏加载弹窗
          this.$message.success('保存成功');
          localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.$emit('update:user', this.user);
          this.isEditing = false;
        } else {
          this.$message.error(res.msg);
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.hideLoading(); // 隐藏加载弹窗
      });
    }
  }
};
</script>

<style scoped>
/deep/ .el-form-item__label {
  font-weight: bold;
}
/*！！！！！更改字体样式，需要更加精准的描述：！！！！*/
.el-descriptions--mini, .el-descriptions--small {
  /* font-size: 22px; */
}

.large-font{
  min-width: 400px!important;
}
.yh{
  margin-left: 10px;
  padding-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

@media (max-width: 980px) {
  .large-font0{
    min-width: 350px!important;
    padding: 10px;
    width: 95%!important;
  }
  .large-font1{

  }
  .large-font{
    font-size: large;
  }

}

</style>
