<template>
  <div>
    <!--    <span class="yh">收益数据</span>-->
    <div slot="header" class="clearfix">
      <div style="margin-bottom: 10px" >

        <div style=""  key="2">
          <el-button type="primary" plain @click="openAddDialog" style="width: 120px;" >新增</el-button>
<!--          <el-button type="info" plain @click="reset" >刷新</el-button>-->

        </div>
      </div>

      <el-table :data="uannounce" stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'}"
                style="width: 100%; padding-top: 10px"
                @selection-change="handleSelectionChange">

        <el-table-column label="id" :min-width="80"  :show-overflow-tooltip="true" align="center" key="3">
          <template v-slot="scope">
            <span>{{ scope.row.id}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否可见" :min-width="80"  :show-overflow-tooltip="true" align="center" key="4">
          <template v-slot="scope">
            <span>{{ scope.row.vis}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="标题" :min-width="120"  :show-overflow-tooltip="true" align="center" key="5">
          <template v-slot="scope">
            <span>{{ scope.row.utitle}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="内容" :min-width="120"  :show-overflow-tooltip="true" align="center" key="6">
          <template v-slot="scope">
            <span>{{ scope.row.ucontent}}</span> <!-- 使用 scope.row 直接引用 -->
          </template>
        </el-table-column>
        <el-table-column label="位置" :min-width="120"  :show-overflow-tooltip="true" align="center" key="7">
          <template v-slot="scope">
            <span>{{ scope.row.urole}}</span> <!-- 弹窗或标题,根据值来判断 -->
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="170" align="center"  key="16">
          <template v-slot="scope">
            <el-button type="primary" size="mini" plain @click="edit(scope.row)" key="17">编辑</el-button>
            <el-button type="danger" size="mini" plain @click="handleDelete(scope.row.id)" key="18" >删除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <div style="display: flex;margin-top: 20px;justify-content: center;align-items: center" key="19"  >
        <el-pagination

            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total=total>
        </el-pagination>
      </div>
      <div >

        <el-dialog title="新增数据" :visible.sync="formVisible"  width="40%" :close-on-click-modal="false">
          <el-form :model="form" style="width: 95%;" >

            <div class="form-row">
              <el-form-item prop="urole" label="展示位置"  :label-width="formLabelWidth">
                <el-input v-model="form.urole"  autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item prop="utitle" label="标题" :label-width="formLabelWidth">
                <el-input v-model="form.utitle" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div class="form-row">
              <el-form-item prop="ucontent" label="内容" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="form.ucontent" autocomplete="off" rows="6" ></el-input>
              </el-form-item>
              <el-form-item prop="vis" label="是否可见" :label-width="formLabelWidth">
                <el-input v-model="form.vis" placeholder="1展示，0不展示" autocomplete="off"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
            <el-button @click="formVisible = false" style="width: 80px">取 消</el-button>
            <el-button type="primary" @click="handleAdd" style="width: 130px" :loading="isloading">提 交</el-button>
          </div>
        </el-dialog>
      </div>
      <div >
        <el-dialog title="编辑" :visible.sync="formVisible2" width="40%" :close-on-click-modal="false">
          <el-form :model="form2" style="width: 95%;">
            <div class="form-row">
              <el-form-item prop="urole" label="展示位置" :label-width="formLabelWidth">
                <el-input v-model="form2.urole" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item prop="utitle" label="标题" :label-width="formLabelWidth">
                <el-input v-model="form2.utitle" autocomplete="off"></el-input>
              </el-form-item>
            </div>
            <div class="form-row">
              <el-form-item prop="ucontent" label="内容" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="form2.ucontent" autocomplete="off" rows="6"></el-input>
              </el-form-item>
              <el-form-item prop="vis" label="是否可见" :label-width="formLabelWidth">
                <el-input v-model="form2.vis" autocomplete="off"></el-input>
              </el-form-item>
            </div>

          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible2 = false">取 消</el-button>
            <el-button type="primary" @click.stop="handleEdit" :loading="isloading">提 交</el-button>
          </div>
        </el-dialog>
      </div>
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import axios, {formToJSON} from "axios";
import { Loading } from 'element-ui';
export default {
  name: 'udata',
  data() {
    return {
      showColumns: {
        selection: false,
        user: false,
        project: true, // 默认显示
        // 其他列...
      },
      isloading:false,
      ist:false,

      isA:null,
      pageNum: 1,
      pageSize: 16,
      username:"",
      appid:'',
      total:0,
      formVisible: false,
      formVisible2: false,
      form: {},
      form2: {},
      formLabelWidth: '120px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids:[],
      formUsers:[],
      restaurants: [],
      lu:[],
      currentUser:{},
      currentUserName:'',
      role1:2,
      urole:1,
      utitle:"",
      uannounce:[],
    }
  },

  mounted() {
    this.loadUser()
    this.currentUser = JSON.parse(localStorage.getItem('honey-user'));
    this.currentUserName =this.currentUser.username;
    this.role1 =this.currentUser.role;
    if (this.role1 === 1) {
      this.load()

    }else {
      this.load2()
    }

  },
  methods: {
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    handleDelete(id){
      // console.log('Deleting project with ID:', id);
      this.$confirm('是否要删除?','确认删除',{type:"warning"}).then(response => {
        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/uannounce/delete/'+id).then((res2) => {
          if (res2.code === '200') {
            this.hideLoading(); // 隐藏加载弹窗
            this.$message.success('删除成功');
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.reset()
          }else {
            this.hideLoading(); // 隐藏加载弹窗
            this.$message.error(res2.mag)
          }
        })
      }).catch((error) => {})
      this.hideLoading(); // 隐藏加载弹窗
    },
    querySearch(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.formUsers.filter(this.createFilter(queryString)) : this.formUsers;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleAdd(){
      this.isloading=true
      this.showLoading(); // 显示加载弹窗
      this.form.udate = new Date(this.form.udate).getTime(); // 转换为时间戳
      this.project = this.form;
      // console.log("this form is:")
      // console.log(this.project);
      this.$request.post('/uannounce/add', this.project).then((res3) => {
        if (res3.code === '200') {
          this.hideLoading(); // 隐藏加载弹窗
          this.isloading=false
          this.$message.success('添加成功');
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.formVisible = false; // 关闭模态框
          // this.load()
          this.reset()
        }else {
          this.$message.error(res3.msg)
          this.isloading=false
          this.hideLoading(); // 隐藏加载弹窗
        }
      })
    },
    edit(row){
      this.form2.udate = new Date(this.form2.udate).getTime(); // 转换为时间戳
      this.form2 = JSON.parse(JSON.stringify(row));
      this.formVisible2=true; // 打开模态框

      // console.log('this.form2 is :')
      // console.log(this.form2);

    },
    loadUser(){
      this.$request.get('/uannounce/selectAll').then((result) => {
        this.lu = result.data
        this.formUsers = this.lu.map(lu=> ({ value: lu.username}));

      }).catch((error) => {})
    },
    load2(pageNum) {
      if (pageNum) {
        this.pageNum = pageNum;
      }
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/uannounce/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize

        }
      }).then(res4 => {
        if (res4.data && res4.data.records) {
          this.uannounce = res4.data.records;
          this.total = res4.data.total;
          this.hideLoading(); // 隐藏加载弹窗
          // console.log('this.res4 is:')
          // console.log(this.res4)
        } else {
          this.uannounce = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    load(pageNum) {
      if (pageNum) {
        this.pageNum = pageNum;
      }
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/uannounce/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(res5 => {
        if (res5.data && res5) {
          this.uannounce = res5.data.records;
          this.total = res5.data.total;
          this.ist = true
          this.hideLoading(); // 隐藏加载弹窗
          // console.log('this.res5 is:')
          // console.log(res5)
        } else {
          this.uannounce = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err2 => {
        console.error(err2); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      if (this.role1 === 1) {
        this.load()
      }else {
        this.load2()
      }
    },
    handleSelectionChange(rows){
      this.ids = rows.map(item => item.id);
    },
    openAddDialog(){
      this.formVisible=true;
    },
    handleEdit(){
      this.isloading=true
      this.form2.udate = new Date(this.form2.udate).getTime(); // 转换为时间戳
      this.project = this.form2;
      this.showLoading(); // 显示加载弹窗
      this.$request.put('/uannounce/update', this.project).then((res7) => {
        if (res7.code === '200') {
          this.formVisible2 = false; // 关闭模态框
          this.isloading=false
          // this.hideLoading(); // 隐藏加载弹窗
          this.$message.success('修改成功');

          // localStorage.setItem('honey-user', JSON.stringify(this.user));

          this.reset()
        }else {
          this.$message.error(res7.msg)
          this.hideLoading(); // 隐藏加载弹窗
          this.isloading=false
        }
      }).catch((error) => { this.isloading=false}) // 可能需要在请求失败时重定向
      ;
      // this.formVisible2=true;
      // this.hideLoading(); // 隐藏加载弹窗
    },
    reset(){
      this.username = "";
      this.projectName ="";
      // this.form2={};
      this. appid=''
      this.form={};
      if (this.role1 === 1) {
        this.load(1)
      }else {
        this.load2(1)
      }
    },

  }
}
</script>

<style scoped>
.clearfix{
  //padding-top: 20px;
}
.el-table .el-table__row {
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}


</style>
