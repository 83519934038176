<template>
  <div>
    <div slot="header" class="clearfix">
      <div style="margin-bottom: 10px">
        <div style="margin-bottom: 10px">
          <el-button type="primary" plain @click="openAddDialog" style="width: 120px;">新增</el-button>
          <el-button type="danger" plain @click="delbatch" :loading="isloading1">批量删除</el-button>
<!--          <el-button type="info" plain @click="reset">刷新</el-button>-->
        </div>
        <el-autocomplete  key="1"
                         style="width: 200px;margin-right: 10px"
                         v-model="username"
                         placeholder="输入或选择用户"
                         :fetch-suggestions="querySearch"
                          clearable
        ></el-autocomplete>

        <el-input style="width: 200px; margin-right: 10px" placeholder="姓名" v-model="name"  clearable></el-input>
        <el-button type="primary" @click="load(1)" style="width: 100px;">查询</el-button>
        <el-button type="primary" plain @click="reset">重置</el-button>

      </div>
<!--      <span class="yh">用户列表</span>-->
      <el-table :data="users" stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'}"
                style="width: 100%; padding-top: 10px"
      @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
<!--        <el-table-column prop="id" label="id" width="50" align="center"></el-table-column>-->
        <el-table-column prop="username" label="用户" :show-overflow-tooltip="true" align="center"  width="110" ></el-table-column>
        <el-table-column prop="name" label="昵称" :show-overflow-tooltip="true" align="center" width="110"></el-table-column>
        <el-table-column prop="role" label="角色"  align="center" width="50"></el-table-column>
        <el-table-column prop="scon" label="内容控制"  align="center" width="100"></el-table-column>
        <el-table-column prop="h5" label="h5控制"  align="center" width="90"></el-table-column>
        <el-table-column prop="connect" label="姓名" :show-overflow-tooltip="true" align="center" width="70"></el-table-column>
        <el-table-column prop="password" label="密码" :show-overflow-tooltip="true" align="center" width="100"></el-table-column>
        <el-table-column prop="wechat" label="微信" :show-overflow-tooltip="true" align="center" width="100"></el-table-column>
        <el-table-column prop="phone" label="电话" :show-overflow-tooltip="true" align="center" width="90"></el-table-column>
        <el-table-column prop="usertype" label="用户类型" align="center" width="80"></el-table-column>
        <el-table-column prop="bank" label="银行" :show-overflow-tooltip="true"  align="center" width="80"></el-table-column>
        <el-table-column prop="bankName" label="支行" :show-overflow-tooltip="true"  align="center" width="70"></el-table-column>
        <el-table-column prop="bankNumber" label="银行卡号" :show-overflow-tooltip="true" align="center" width="70"></el-table-column>
        <el-table-column prop="address" label="支行地址" :show-overflow-tooltip="true" align="center" width="70"></el-table-column>
        <el-table-column prop="email" label="邮箱" :show-overflow-tooltip="true" align="center" width="80"></el-table-column>
        <el-table-column prop="channel" label="运营方式" :show-overflow-tooltip="true" align="center" width="80"></el-table-column>
        <el-table-column prop="newbie" label="经验" :show-overflow-tooltip="true" align="center" width="70"></el-table-column>
        <el-table-column label="操作" width="170" :show-overflow-tooltip="true" align="center">
          <template v-slot="scope">
            <el-button type="primary" size="mini" plain @click="edit(scope.row)">编辑</el-button>
            <el-button type="danger"   size="mini" plain @click="del(scope.row.id)" :loading="isloading2">删除</el-button>
          </template>
        </el-table-column>

        <el-table-column label="创建日期" :min-width="150" :show-overflow-tooltip="true" align="center"  key="14">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.creatTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="更新日期" :min-width="150" :show-overflow-tooltip="true" align="center" key="25">
          <template v-slot="scope">
            <div style="white-space: nowrap;">
      <span>
        {{
          (() => {
            const date = new Date(scope.row.updateTime);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
          })()
        }}
      </span>
            </div>
          </template>
        </el-table-column>


      </el-table>

<!--      <div style="margin: 10px 0">-->
        <div style="display: flex;justify-content: center;margin-top: 20px">
        <el-pagination

            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="pageSize"
            layout="total,  prev, pager, next"
            :total=total>
        </el-pagination>
      </div>
    </div>
    <div  >
      <el-dialog title="新增用户" :visible.sync="formVisible"   width="30%" :close-on-click-modal="false" :style="{ borderRadius: '25px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }">
        <el-form :model="form" style="width: 70%;" >
          <el-form-item label="用户名" :label-width="formLabelWidth">
            <el-input v-model="form.username" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" :label-width="formLabelWidth">
            <el-input v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="昵称" :label-width="formLabelWidth">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="类型" :label-width="formLabelWidth">
            <el-select
                v-model="form.usertype"
                size="small"
                placeholder="用户类型"
                prefix-icon="el-icon-office-building">
              <el-option label="个人" value="个人"></el-option>
              <el-option label="个体户" value="个体户"></el-option>
              <el-option label="公司" value="公司"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
          <el-button @click="formVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleAdd" :loading="isloading" style="width: 120px">提 交</el-button>
        </div>
      </el-dialog>
    </div>
<div >
  <el-dialog title="编辑用户" :visible.sync="formVisible2" width="40%" :close-on-click-modal="false">
    <el-form :model="form2" style="width: 95%;" label-position="top">
      <div class="form-row">
        <el-form-item prop="username" label="用户名" class="form-item">
          <el-input v-model="form2.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="name" label="昵称" class="form-item">
          <el-input v-model="form2.name" autocomplete="off"></el-input>
        </el-form-item>

      </div>
      <div class="form-row">

        <el-form-item prop="role" label="角色" class="form-item">
          <el-input v-model="form2.role" placeholder="0待审核；1管理员；2用户" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="scon" label="内容控制" class="form-item">
          <el-input v-model="form2.scon" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="h5" label="h5控制" class="form-item">
          <el-input v-model="form2.h5" autocomplete="off"></el-input>
        </el-form-item>
      </div>
      <div class="form-row">
        <el-form-item prop="password" label="密码" class="form-item">
          <el-input v-model="form2.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="connect" label="姓名" class="form-item">
          <el-input v-model="form2.connect" autocomplete="off"></el-input>
        </el-form-item>

      </div>
      <div class="form-row" >
        <el-form-item prop="wechat" label="微信" class="form-item">
          <el-input v-model="form2.wechat" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="usertype" label="用户类型" class="form-item">
          <el-select
              v-model="form2.usertype"
              size="small"
              placeholder="用户类型"
              prefix-icon="el-icon-office-building">
            <el-option label="个人" value="个人"></el-option>
            <el-option label="个体户" value="个体户"></el-option>
            <el-option label="公司" value="公司"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="form-row">
        <el-form-item prop="bank" label="邮箱" class="form-item">
          <el-input v-model="form2.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="usertype" label="电话" class="form-item">
          <el-input v-model="form2.phone" autocomplete="off"></el-input>
        </el-form-item>

      </div>
      <div class="form-row">
        <el-form-item prop="bank" label="银行" class="form-item">
          <el-input v-model="form2.bank" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="bankName" label="支行" class="form-item">
          <el-input v-model="form2.bankName" autocomplete="off"></el-input>
        </el-form-item>

      </div>
      <div class="form-row">
        <el-form-item prop="bankNumber" label="银行卡号" class="form-item">
          <el-input v-model="form2.bankNumber" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="address" label="支行地址" class="form-item">
          <el-input v-model="form2.address" autocomplete="off"></el-input>
        </el-form-item>

      </div>
      <div class="form-row">
        <el-form-item prop="channel" label="运营方式" class="form-item">
          <el-input v-model="form2.channel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="newbie" label="经验" class="form-item">
          <el-input v-model="form2.newbie" autocomplete="off"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center; display: flex;justify-content: center;">
      <el-button @click="formVisible2 = false">取 消</el-button>
      <el-button type="primary" @click.stop="handleEdit" :loading="isloading" style="width: 120px" >提 交</el-button>
    </div>
  </el-dialog>
  </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import axios from "axios";
import { Loading } from 'element-ui';
export default {
  name: 'users',
  data() {
    return {
      isloading:false,
      isloading1:false,
      isloading2:false,
      users: [],
      user: { name: "" },
      pageNum: 1,
      pageSize: 12,
      username:"",
      name:"",
      total:0,
      formVisible: false,
      formVisible2: false,
      form: {
      },
      form2: {
      },
      formLabelWidth: '80px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids:[]
    }
  },
  mounted() {
    this.loadUser()
    this.load()
  },

  methods: {
    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },

    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    loadUser(){

      this.$request.get('/user/selectAll').then((result) => {
        if (result.data) {
          this.lu = result.data
          this.formUsers = this.lu.map(lu=> ({ value: lu.username}));
          this.hideLoading(); // 隐藏加载弹窗
        } else {

          localStorage.removeItem("honey-user")
          this.$router.push('/login');
        }


      })
    },
    querySearch(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.formUsers.filter(this.createFilter(queryString)) : this.formUsers;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    delbatch(){
      if(!this.ids.length){
        this.$message.warning('请选择要删除的数据')
        return
      }
      this.$confirm('是否要批量删除?','确认删除',{type:"warning"}).then(response => {
        this.isloading1=true
        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/user/delete/batch',{data:this.ids}).then((res) => {
          if (res.code === '200') {
            this.hideLoading(); // 隐藏加载弹窗
            this.$message.success('删除成功');
            this.isloading1=false
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            this.load()
          }else {
            this.$message.error(res.mag)
            this.hideLoading(); // 隐藏加载弹窗
            this.isloading1=false
          }
        })
      }).catch((error) => {this.isloading=false; this.hideLoading(); })

    },
    handleSelectionChange(rows){
    this.ids = rows.map(item => item.id);
    },
    del(id){
      // console.log('Deleting user with ID:', id);
  this.$confirm('是否要删除?','确认删除',{type:"warning"}).then(response => {
    this.showLoading(); // 显示加载弹窗
    this.isloading2=true
    this.$request.delete('/user/delete/'+id).then((res) => {
      if (res.code === '200') {
        this.hideLoading(); // 隐藏加载弹窗
        this.$message.success('删除成功');
        this.isloading2=false;
        // localStorage.setItem('honey-user', JSON.stringify(this.user));
        // this.formVisible2 = false; // 关闭模态框
        this.load()
         }else {
            this.$message.error(res.mag)
        this.isloading2=false;
        this.hideLoading(); // 隐藏加载弹窗
          }
        })
      }).catch((error) => { this.hideLoading(); this.isloading=false;})
     // 隐藏加载弹窗

    },
    edit(row){
      this.form2 = JSON.parse(JSON.stringify(row));
     this.formVisible2=true; // 打开模态框
      // console.log("this JSON.stringify is  ")
      // console.log(localStorage)
      // this.form2 = JSON.parse(localStorage.getItem("honey-user"))
    },
    reflash(){
      this.load(1)
    },
    openAddDialog() {
      this.formVisible = true; // 打开模态框

    },
    handleAdd(){
      this.isloading = true;
      this.user = this.form;
      this.showLoading(); // 显示加载弹窗
      this.$request.post('/user/add', this.user).then((res) => {
        if (res.code === '200') {

          this.$message.success('添加成功');
          this.hideLoading(); // 隐藏加载弹窗
          this.isloading=false
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.formVisible = false; // 关闭模态框
          // this.load()
          this.reset()
        }else {
          this.hideLoading(); // 隐藏加载弹窗
          this.$message.error(res.msg)
        }
      }).catch((error) => {this.hideLoading(); this.isloading=false})
       // 隐藏加载弹窗
    },

    handleEdit(){
      this.isloading = true;
      this.user = this.form2;
      this.$request.put('/user/update', this.user).then((res) => {
        this.showLoading(); // 显示加载弹窗
        if (res.code === '200') {

          this.$message.success('修改成功');
          this.hideLoading(); // 隐藏加载弹窗
          this.isloading=false
          // localStorage.setItem('honey-user', JSON.stringify(this.user));
          this.formVisible2 = false; // 关闭模态框
          this.reset()
        }else {
          this.$message.error(res.msg)
          this.hideLoading(); // 隐藏加载弹窗
          this.isloading=false
        }
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.hideLoading(); // 隐藏加载弹窗
        this.isloading=false

      });
    },
    reset(){
      this.username = "";
      this.name ="";
      this.form2={};
          this.form={};
          this.load(1)
    },
    load(pageNum) {
      if (pageNum) {
        this.pageNum = pageNum;
      }
      this.showLoading(); // 显示加载弹窗
      this.$request.get('/user/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.username,
          name: this.name,
        }
      }).then(res => {
        if (res.data && res.data.records) {
          this.users = res.data.records;
          this.total = res.data.total;
          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.users = [];
          this.total = 0; // 或者其他处理逻辑
          this.hideLoading(); // 隐藏加载弹窗
        }
      }).catch(err => {
        console.error(err); // 处理错误
        this.hideLoading(); // 隐藏加载弹窗
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load()
    },
  }
}
</script>

<style scoped>

.el-table .el-table__row {
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}
</style>
