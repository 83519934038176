<template>
  <div>
    <div slot="header" class="clearfix">
      <div style="margin-bottom: 10px; display: flex; align-items: center; justify-content: space-between;" v-if="isA">
        <el-form :model="form5" >
          <el-row>
            <el-input v-model="form5.push5" placeholder="展示量" style="width: 200px;margin-right: 10px"   clearable></el-input>
            <el-input v-model="form5.tap5" placeholder="点击量" style="width: 200px;margin-right: 10px"  clearable></el-input>
            <el-input v-model="form5.income5" placeholder="总收益" style="width: 200px;margin-right: 10px"  clearable></el-input>
            <el-input  v-model="th" style="width: 100px;margin-right: 10px"  clearable > </el-input>
            <el-button type="primary" plain @click="reset" >重置</el-button>
            <el-button type="danger" plain @click="delbatch" :loading="isloading1">批量删除</el-button>
          </el-row>

        </el-form>

      </div>

      <div style="margin-bottom: 10px; display: flex; align-items: center; justify-content: space-between;">
        <el-form>
      <!-- 日期选择器 -->
          <el-autocomplete v-if="isA" key="1"
                           style="width: 200px;;padding-right: 10px"
                           v-model="username"
                           placeholder="输入或选择用户"
                           :fetch-suggestions="querySearch2"
                           clearable
          ></el-autocomplete>
        <el-autocomplete  class="inline-input" style="width: 200px;padding-right: 10px"
                          v-model="form4.project"
                          :fetch-suggestions="querySearch"
                          placeholder="输入或选择应用"
                          v-if="isA"
                          clearable
        >
        </el-autocomplete>
      <el-date-picker v-model="startDate" type="date" placeholder="开始日期" style="width: 200px;margin-right: 10px" v-if="isA"></el-date-picker>
      <el-date-picker v-model="endDate" type="date" placeholder="结束日期" style="width: 200px;margin-right: 10px" v-if="isA"></el-date-picker>
      <el-button type="primary" @click="loadPushStats(startDate, endDate, username, projectName)" style="width: 100px;padding-right: 10px" v-if="isA">查询</el-button>
          <el-button type="primary" plain @click="reset" v-if="isA">重置</el-button>
          <el-button type="primary" plain @click="flash" v-if="isD">刷新</el-button>

        </el-form>
        <div style="display: flex; justify-content: space-between; font-weight: bold;" v-if="isA">
          <div style="padding-right: 50px;">
            <span style="color: slategray">总推送：</span><span style="color: #0685cc;">{{ this.tp }}</span>
          </div>
          <div style="text-align: right; padding-right: 20px;">
            <span style="color: slategray">总点击：</span><span style="color: #0685cc;">{{ this.tt }}</span>
          </div>
        </div>
      </div>

      <div style="display: flex; gap: 20px; margin-top: 20px; justify-content: space-between;" v-if="isB">

        <el-table :data="mergedPushStats" stripe :header-cell-style="{ backgroundColor: '#dae9f8', font: 'bold' }" style="flex: 1;">
          <el-table-column prop="project_Name" align="center" label="应用名称"></el-table-column>

          <el-table-column label="实时推送量" align="center" prop="todayPushCount"></el-table-column>
          <el-table-column :label="`${yesterdayLabel} 推送量`" align="center" prop="yesterdayPushCount"></el-table-column>
          <el-table-column :label="`${yesterdayLabel2} 推送量`" align="center" prop="dayBeforeYesterdayPushCount"></el-table-column>
          <el-table-column :label="`${yesterdayLabel3} 推送量`" align="center" prop="dayBeforeYesterdayPushCount2"></el-table-column>
        </el-table>

      </div>


      <div v-if="isA">
        <el-table :data="pushStats"   stripe :header-cell-style="{backgroundColor:'#dae9f8' ,font:'bold'} "
                  style="width: 100%; padding-top: 10px">
          <el-table-column prop="username" align="center" label="用户" v-if="isA"></el-table-column>

          <el-table-column prop="project_Name" align="center" label="应用名称"></el-table-column>
          <el-table-column prop="unique_ip_count"  align="center" label="解锁日活" v-if="isA"></el-table-column>
          <el-table-column label="推送量" align="center">
            <template slot-scope="scope">
              {{ scope.row.push_count - scope.row.tap_count }}
            </template>
          </el-table-column>
          < <!-- 推送比例计算列 -->
          <el-table-column align="center" label="推送占比" v-if="isA">
            <template slot-scope="scope">
              {{ calculatePushRatio(scope.row.push_count, scope.row.tap_count) }}
            </template>
          </el-table-column>

          <el-table-column prop="tap_count"  align="center" label="点击量" v-if="isA"></el-table-column>

          <!-- 点击比例计算列 -->
          <el-table-column align="center" label="点击占比" v-if="isA">
            <template slot-scope="scope">
              {{ calculateTapRatio(scope.row.tap_count) }}
            </template>
          </el-table-column>

          <el-table-column align="center" label="调和展示量" v-if="isA">
            <template slot-scope="scope">
              {{ calculatePushRatioip(scope.row.push_count, scope.row.tap_count,scope.row.unique_ip_count) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="调和占比" v-if="isA">
            <template slot-scope="scope">
              {{ calculateTapRatioip(scope.row.push_count, scope.row.tap_count,scope.row.unique_ip_count) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="分配展示量" v-if="isA">
            <template slot-scope="scope">
              {{ calculatePushRatio2(scope.row.push_count, scope.row.tap_count, scope.row.unique_ip_count) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="点击量" v-if="isA">
            <template slot-scope="scope">
              {{ calculateTapRatio2(scope.row.tap_count) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="收益（元）" v-if="isA">
            <template slot-scope="scope">
              {{ calculateip3(scope.row.push_count, scope.row.tap_count,scope.row.unique_ip_count) }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="千次展示收益" v-if="isA">
            <template slot-scope="scope">
              {{ calculate05(scope.row.push_count, scope.row.tap_count,scope.row.unique_ip_count) }}
            </template>
          </el-table-column>
        </el-table>

      </div>

      <div style="  margin-top: 20px; font-size: 12px; color: #7e7d7d;" v-if="isB">注：推送量不同于有效广告展示量，仅能反映展示量的大体趋势，作为参考</div>

    </div>

  </div>
</template>

<script>
import { Loading } from 'element-ui';
import E from 'wangeditor';
import Log from "@/views/manager/log.vue";

export default {
  name: 'contents',
  data() {
    return {
      user: JSON.parse(localStorage.getItem('honey-user') || '{}'),
      isloading: false,
      isloading1: false,
      isloading2: false,
      startDate: '',   // 开始日期
      endDate: '',     // 结束日期
      pushStats: [],
      pushStatsToday: [], // 今日数据
      pushStatsYesterday: [], // 昨日数据
      pushStatsDayBeforeYesterday: [], // 前日数据
      pushStatsDayBeforeYesterday2: [], // 大前日数据
      mergedStats: [],
      mergedPushStats: [],
      form3: {},
      isA: null,
      isB: null,
      isD: null,
      contents: [],
      content: {},
      pageNum: 1,
      pageSize: 14,
      username: "",
      appid: '',
      projectName: "",
      total: 0,
      formVisible: false,
      formVisible2: false,
      form: {},
      form4: [],
      form5: {},
      formLabelWidth: '80px',
      originalUser: {},  // 存储初始数据，用于取消时恢复
      ids: [],
      formUsers: [],
      restaurants: [],
      lu: [],
      currentUser: {},
      currentUserName: '',
      role1: 2,
      title1: "",
      DayIncome: 0,
      username2: '',
      tp: 0,
      tt: 0,
      tr: 0,
      th:0.7, //调和参数

    }
  },
  computed: {
    yesterdayLabel() {
      const now = new Date();
      now.setDate(now.getDate() - 1); // 获取昨天的日期
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从 0 开始，所以要加 1
      const day = now.getDate();

      return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`; // 格式化日期为 YYYY-MM-DD
    },
    yesterdayLabel2() {
      const now = new Date();
      now.setDate(now.getDate() - 2); // 获取昨天的日期
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从 0 开始，所以要加 1
      const day = now.getDate();

      return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`; // 格式化日期为 YYYY-MM-DD
    },

    yesterdayLabel3() {
      const now = new Date();
      now.setDate(now.getDate() - 3);
      const year = now.getFullYear();
      const month = now.getMonth() + 1; // 月份从 0 开始，所以要加 1
      const day = now.getDate();

      return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`; // 格式化日期为 YYYY-MM-DD
    }

  },


  mounted() {
    this.loadUser()
    this.loadprojects()
    this.currentUser = JSON.parse(localStorage.getItem('honey-user'));
    this.currentUserName = this.currentUser.username;
    this.role1 = this.currentUser.role;
    // this.load()
    if (this.role1 === 1) {
      this.isA = true
      this.isD = false
      this.isB = false
      this.loadPushStats0()
      this.loadAllPushStatsip3()
    }
    if (this.role1 === 2) {
      this.showLoading(); // 显示加载弹窗
      this.loadAllPushStats(); // 页面加载时，自动加载三天的数据
      this.loadAllPushStatsip()
      this.isA = false
      this.isD = true
      this.isB = true
    }
    this.loadUan1()
  },
  methods: {

    showLoading() {
      this.loadingInstance = Loading.service({
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
    },
    // 隐藏加载弹窗
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close();
      }
    },
    loadUan1() {
      this.getD().then(() => {
        return this.$request.get('/uannounce/selectAll');
      }).then((result) => {
        this.lu = result.data;
        if (this.lu) {
          this.formUan1 = this.lu.map(lu => ({
                utitle: lu.utitle,
                ucontent: lu.ucontent,
                id: lu.id,
                urole: lu.urole,
                vis: lu.vis
              })
          );
        }

        const targetItem1 = this.formUan1.find(item => item.id === 1);
        const targetItem2 = this.formUan1.find(item => item.id === 2);
        const targetItem3 = this.formUan1.find(item => item.id === 3);
        if (this.DayIncome1 !== 0) {
          this.title1 = targetItem3.ucontent;
        }
        if (this.DayIncome1 === 0) {
          const now = new Date();
          const hours = now.getHours();
          const targetItem3 = this.formUan1.find(item => item.id === 3);
          if (targetItem3) {
            if (targetItem3.vis === 1 && hours > 19) {

              this.title1 = targetItem3.ucontent;
            } else {
              if (hours > 19) {
                this.title1 = targetItem1.ucontent;

              } else {
                this.title1 = targetItem2.ucontent;
              }
            }
          }


        }
        this.$emit('update:title1', this.title1);
      });
    },
    getD() {
      return this.$request.get('/udata/DayselectByUsername', {
        params: {
          username: this.user.username,
        }
      }).then(res => {
        if (res.data) {
          this.DayIncome1 = res.data.DayIncome;
          // console.log("this.DayIncome1 in getD:");
          // console.log(this.DayIncome1);
        } else {
          console.log(res);
        }
      }).catch(err1 => {
        console.error(err1); // 处理错误
      });
    },

    mergePushStatsData() {
      // 存放所有的应用数据
      const allData = [];

      // 所有日期的数据
      const allStats = [
        { data: this.pushStatsToday, label: '今日数据' },
        { data: this.pushStatsYesterday, label: '昨日数据' },
        { data: this.pushStatsDayBeforeYesterday, label: '前日数据' },
        { data: this.pushStatsDayBeforeYesterday2, label: '大前日数据' }
      ];

      // 用来存储所有应用的名称
      const allProjectNames = new Set();

      // 获取所有应用的名称
      allStats.forEach(stats => {
        stats.data.forEach(item => {
          allProjectNames.add(item.project_Name); // 加入所有的应用名称
        });
      });

      // 遍历所有应用名称并创建一个完整的记录
      allProjectNames.forEach(projectName => {
        const existingItem = {
          project_Name: projectName || '未知应用',
          todayPushCount: 0,
          yesterdayPushCount: 0,
          dayBeforeYesterdayPushCount: 0,
          dayBeforeYesterdayPushCount2: 0
        };

        allStats.forEach(stats => {
          const item = stats.data.find(i => i.project_Name === projectName);

          if (item) {
            const pushCount = item.push_count || 0;  // 如果为空，默认为 0
            const tapCount = item.tap_count || 0;    // 如果为空，默认为 0

            const pushDifference = pushCount - tapCount;
            const pushValue = pushDifference < 20 ? '数据过少' : pushDifference;

            if (stats.label === '今日数据') {
              existingItem.todayPushCount = pushValue;
            } else if (stats.label === '昨日数据') {
              existingItem.yesterdayPushCount = pushValue;
            } else if (stats.label === '前日数据') {
              existingItem.dayBeforeYesterdayPushCount = pushValue;
            } else if (stats.label === '大前日数据') {
              existingItem.dayBeforeYesterdayPushCount2 = pushValue;
            }
          }
        });

        // 将当前应用的记录添加到 allData 中
        allData.push(existingItem);
      });

      // 打印合并后的数据
      // console.log('Merged data:', allData);

      // 排序前检查 project_Name 是否存在
      this.mergedPushStats = allData.sort((a, b) => {
        const nameA = (a.project_Name || '未知应用').toString();
        const nameB = (b.project_Name || '未知应用').toString();
        return nameA.localeCompare(nameB);
      });
    },

    loadPushStatsForDay2(startOffset, endOffset, resultArray) {
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + startOffset).getTime();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + endOffset).getTime();
      this.$request
          .get('/conincomes/selectPushStatsByDate', {
            params: {
              startDate: startOfDay, // 起始时间戳
              endDate: endOfDay, // 结束时间戳
              username: this.currentUserName, // 可选
              projectName: this.form4.project // 可选
            }
          })
          .then(res => {
            if (res.data && res.data.length > 0) {
              // console.log('this data is :')
              // console.log(res);
              this[resultArray] = res.data; // 将数据存储到对应数组
              this.$nextTick(() => {
                this.mergePushStatsData();
                this.hideLoading();
              });
            } else {
              this[resultArray] = []; // 清空对应数组
              // 数据加载完毕后合并
              this.$nextTick(() => {
                this.mergePushStatsData();
                this.hideLoading();
              });
            }

          })
          .catch(err => {
            console.error('加载数据失败:', err);
            this.hideLoading();
          });
    },
    loadPushStatsForDayip(startOffset, endOffset, resultArray) { // 用户获取ip
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + startOffset).getTime();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + endOffset).getTime();
      this.$request
          .get('/ipcount/selectPushStatsByDate', {
            params: {
              startDate: startOfDay, // 起始时间戳
              endDate: endOfDay, // 结束时间戳
              username: this.currentUserName, // 可选
              projectName: this.form4.project // 可选
            }
          })
          .then(res => {
            if (res.data && res.data.length > 0) {
              // console.log('this data ip is :')
              // console.log(res);
              this[resultArray] = res.data; // 将数据存储到对应数组
            } else {
              this[resultArray] = []; // 清空对应数组
            }
            this.hideLoading();
          })
          .catch(err => {
            console.error('加载数据失败:', err);
            this.hideLoading();
          });
    },
    loadPushStatsForDayip3(startOffset, endOffset, resultArray) { // 管理员获取ip
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + startOffset).getTime();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + endOffset).getTime();
      this.$request
          .get('/ipcount/selectPushStatsByDate', {
            params: {
              startDate: startOfDay, // 起始时间戳
              endDate: endOfDay, // 结束时间戳
              username: this.username, // 可选
              projectName: this.form4.project // 可选
            }
          })
          .then(res => {
            if (res.data && res.data.length > 0) {
              // console.log('this ip data  is :')
              // console.log(res);
              this[resultArray] = res.data; // 将数据存储到对应数组
            } else {
              this[resultArray] = []; // 清空对应数组
            }
            this.hideLoading();
          })
          .catch(err => {
            console.error('加载数据失败:', err);
            this.hideLoading();
          });
    },

    loadAllPushStatsip() { //用户获取ip
      this.showLoading()
      this.loadPushStatsForDayip(0, 1, 'pushStatsTodayip'); // 今日数据
      this.loadPushStatsForDayip(-1, 0, 'pushStatsYesterdayip'); // 昨日数据
      this.loadPushStatsForDayip(-2, -1, 'pushStatsDayBeforeYesterdayip'); // 前日数据
      this.hideLoading()
    },
    loadAllPushStatsip3() {  //管理员获取ip
      this.showLoading()
      this.loadPushStatsForDayip3(0, 1, 'pushStatsTodayip'); // 今日数据
      this.loadPushStatsForDayip3(-1, 0, 'pushStatsYesterdayip'); // 昨日数据
      this.loadPushStatsForDayip3(-2, -1, 'pushStatsDayBeforeYesterdayip'); // 前日数据
      this.hideLoading()
    },
    loadPushStatsForDay3(startOffset, endOffset, resultArray) {

      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + startOffset).getTime();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + endOffset).getTime();

      this.$request
          .get('/conincomes/selectPushStatsByDate', {
            params: {
              startDate: startOfDay, // 起始时间戳
              endDate: endOfDay, // 结束时间戳
              username: this.username, // 可选
              projectName: this.form4.project // 可选
            }
          })
          .then(res => {
            if (res.data && res.data.length > 0) {
              this[resultArray] = res.data; // 将数据存储到对应数组
              this.$nextTick(() => {
                this.mergePushStatsData();
                this.hideLoading();
              });
            } else {
              this[resultArray] = []; // 清空对应数组
              this.$nextTick(() => {
                this.mergePushStatsData();
                this.hideLoading();
              });
            }

          })
          .catch(err => {
            console.error('加载数据失败:', err);
            this.hideLoading();
          });
    },
    loadAllPushStats3() {
      this.showLoading()
      this.loadPushStatsForDay3(0, 1, 'pushStatsToday'); // 今日数据
      this.loadPushStatsForDay3(-1, 0, 'pushStatsYesterday'); // 昨日数据
      this.loadPushStatsForDay3(-2, -1, 'pushStatsDayBeforeYesterday'); // 前日数据
      this.loadPushStatsForDay3(-3, -2, 'pushStatsDayBeforeYesterday2'); // 大前日数据
      this.hideLoading()
    },
    // 分别加载今日、昨日、前日数据
    loadAllPushStats() {
      this.showLoading()
      this.loadPushStatsForDay2(0, 1, 'pushStatsToday'); // 今日数据
      this.loadPushStatsForDay2(-1, 0, 'pushStatsYesterday'); // 昨日数据
      this.loadPushStatsForDay2(-2, -1, 'pushStatsDayBeforeYesterday'); // 前日数据
      this.loadPushStatsForDay2(-3, -2, 'pushStatsDayBeforeYesterday2'); // 大前日数据
      this.hideLoading()
    },

    openAddDialog(){
      this.formVisible=true;
    },
    delbatch(){

      this.$confirm('是否要删除10日前数据?','确认删除',{type:"warning"}).then(response => {
        this.isloading1=true
        this.showLoading(); // 显示加载弹窗
        this.$request.delete('/conincomes/batchdelete').then((res) => {
          if (res.code === '200') {
            this.hideLoading(); // 隐藏加载弹窗
            this.$message.success('删除成功');
            this.isloading1=false
            // localStorage.setItem('honey-user', JSON.stringify(this.user));
            // this.formVisible2 = false; // 关闭模态框
            // this.load()
          }else {
            this.$message.error("无数据")
            this.hideLoading(); // 隐藏加载弹窗
            this.isloading1=false
          }
        })
      }).catch((error) => {this.isloading=false; this.hideLoading(); })

    },
    // 计算推送比例
    calculatePushRatio(push_count,tap_count) {
      return ((push_count-tap_count) / this.tp * 100).toFixed(2) + '%';
    },
    calculatePushRatio2(push_count,tap_count,unique_ip_count) { //分配展示量
      return (this.form5.push5*((push_count-tap_count) *this.th +unique_ip_count*(1-this.th)) / (this.tr*(1-this.th)+this.tp*this.th)).toFixed(0) ;
    },
    calculatePushRatioip(push_count,tap_count,unique_ip_count) {
      return ((push_count-tap_count) *this.th +unique_ip_count*(1-this.th)).toFixed(0) ;
    },
    // 计算点击比例
    calculateTapRatio(tap_count) {
      return (tap_count / this.tt * 100).toFixed(2) + '%';
    },
    calculateTapRatio2(tap_count) {
      return (tap_count / this.tt * this.form5.tap5 ).toFixed(0);
    },
    calculateTapRatioip(push_count,tap_count,unique_ip_count) {
      return (((push_count-tap_count) *this.th +unique_ip_count*(1-this.th)) / (this.tr*(1-this.th)+this.tp*this.th) * 100).toFixed(2) + '%';
    },
    calculate3(push_count,tap_count) {
      return ((this.form5.income5 *0.95 * (push_count-tap_count) / this.tp)+(this.form5.income5 *0.05 * tap_count / this.tt )).toFixed(2);
    },
    calculateip3(push_count,tap_count,unique_ip_count) {
      return (((push_count-tap_count) *this.th +unique_ip_count*(1-this.th))/ (this.tr*(1-this.th)+this.tp*this.th) *this.form5.income5).toFixed(2);
    },
    calculate4(push_count,tap_count,unique_ip_count) {  //调和展示量计算cpm
      return (((push_count-tap_count) *this.th +unique_ip_count*(1-this.th))/ (this.tr*(1-this.th)+this.tp*this.th) *this.form5.income5
          /((push_count-tap_count) *this.th +unique_ip_count*(1-this.th))*1000).toFixed(2);
    },
    calculate04(push_count,tap_count,unique_ip_count) {  //分配解锁数计算cpm
      return (1*1000).toFixed(2);
    },
    calculate05(push_count,tap_count,unique_ip_count) {  //推送数计算cpm   收益除以展示数*1000
      return ((((push_count-tap_count) *this.th +unique_ip_count*(1-this.th))/ (this.tr*(1-this.th)+this.tp*this.th) *this.form5.income5)/(push_count-tap_count)*1000).toFixed(2);
    },
    qx(){
      this.formVisible = false
      this.username = "";
      this.projectName ="";
      this.appid=''
      this.form={};

    },
    querySearch(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.form4.filter(this.createFilter(queryString)) : this.form4;
      cb(results);
    },
    querySearch2(queryString, cb) {
      // 假设你有一个用户数组，你可以根据输入的内容过滤用户

      const results = queryString ? this.formUsers.filter(this.createFilter(queryString)) : this.formUsers;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    createFilter2(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadUser(){

      this.$request.get('/user/selectAll').then((result) => {
        this.lu = result.data
        this.formUsers = this.lu.map(lu=> ({ value: lu.username}));
        this.hideLoading(); // 隐藏加载弹窗
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.$router.push('/login'); // 可能需要在请求失败时重定向

      });
    },
    loadprojects(){

      this.$request.get('/projects/selectAll').then((result) => {
        this.lu = result.data
        this.form4 = this.lu.map(lu=> ({ value: lu.projectName}));
        this.hideLoading(); // 隐藏加载弹窗
      }).catch(error => {
        console.error(error); // 处理错误情况
        this.$router.push('/login'); // 可能需要在请求失败时重定向

      });
    },
    load(pageNum) {
      this.showLoading(); // 显示加载弹窗
      if (pageNum) {
        this.pageNum = pageNum;
      }
      this.$request.get('/conincomes/selectByPage', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: this.username,
          appid: this.appid,
          projectName:this.projectName,
        }
      }).then(res5 => {
        if (res5.data && res5.data.records) {
          this.contents = res5.data.records;
          this.total = res5.data.total;

          this.hideLoading(); // 隐藏加载弹窗
        } else {
          this.hideLoading(); // 隐藏加载弹窗
          this.contents = [];
          this.total = 0; // 或者其他处理逻辑
        }
      }).catch(err2 => {
        this.hideLoading(); // 隐藏加载弹窗
        console.error(err2); // 处理错误
      });
    },
    flash(){
      this.showLoading()
      setTimeout(() => {
        this.loadAllPushStats(); // 页面加载时，自动加载三天的数据
        this.hideLoading();
      }, 500);
    },
    loadPushStats0(startDate, endDate, username, projectName) {
      // console.log('this is begin')
      this.showLoading(); // 显示加载弹窗
      const now = new Date();
      const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()+1).getTime();

      this.$request
          .get('/conincomes/selectPushStatsByDate', {
            params: {
              startDate: startOfDay, // 起始时间戳
              endDate: endOfDay, // 结束时间戳
            }

      }).then(res => {
        if (res.data && res.data.length > 0) {
          this.pushStats = res.data; // 获取推送统计数据
          // console.log('this data is :')
          // console.log(res);
          const totalPushCount = res.data.reduce((total, item) => total + item.push_count, 0);
          const totalTapCount = res.data.reduce((total, item) => total + item.tap_count, 0);
          const totalTrCount = res.data.reduce((total, item) => total + item.unique_ip_count, 0);
          this.tp=totalPushCount-totalTapCount
          this.tt=totalTapCount
          this.tr =totalTrCount
          if(this.tr===0){
            this.tr=1

          }
          if(this.tt===0){
            this.tt=1

          }

          this.hideLoading(); // 隐藏加载弹窗
          // console.log('总推送量:', this.tp);  // 输出
          // console.log('总点击量:', this.tt);    // 输出
        } else {
          this.pushStats = [];
          // console.log('this data is :')
          // console.log(res);
          this.hideLoading(); // 隐藏加载弹窗
        }


      }).catch(err => {
        // console.log('this data is :')
        console.log(err);
        this.hideLoading(); // 隐藏加载弹窗
        console.error(err); // 处理错误
      });
    },
    loadPushStats(startDate, endDate, username, projectName) {
      this.showLoading(); // 显示加载弹窗
      if (!this.startDate || !this.endDate) {
        this.$message.error("请先选择日期！");
        this.hideLoading(); // 隐藏加载弹窗
        return;
      }

      // 检查是否选择了日期
      // console.log(this.startDate.toISOString())
      const startDate1 = new Date(this.startDate.toISOString()); // 解析 ISO 日期
      const timestamp = startDate1.getTime(); // 获取 Unix 时间戳，单位是毫秒
      // console.log(timestamp)
      const endDate1 = new Date(this.endDate.toISOString()); // 解析 ISO 日期
      const timestamp2 = endDate1.getTime(); // 获取 Unix 时间戳，单位是毫秒
      // console.log(timestamp2)
      this.$request.get('/conincomes/selectPushStatsByDate', {
        params: {
          startDate: timestamp,
          endDate:timestamp2,
          username: this.username,   // 可选
          projectName: this.form4.project          // 可选
        }

      }).then(res => {
        if (res.data && res.data.length > 0) {
          if(this.username){
            this.loadAllPushStats3()
            this.isB=true
          }
          this.pushStats = res.data; // 获取推送统计数据
          // console.log('this data is :')
          // console.log(res);
          const totalPushCount = res.data.reduce((total, item) => total + item.push_count, 0);
          const totalTapCount = res.data.reduce((total, item) => total + item.tap_count, 0);
          const totalTrCount = res.data.reduce((total, item) => total + item.unique_ip_count, 0);
          this.tp=totalPushCount-totalTapCount
          this.tt=totalTapCount
          this.tr =totalTrCount
          if(this.tr===0){
            this.tr=1

          }
          if(this.tt===0){
            this.tt=1

          }
          this.hideLoading(); // 隐藏加载弹窗
          // console.log('总推送量:', this.tp);  // 输出
          // console.log('总点击量:', this.tt);    // 输出
        } else {
          this.pushStats = [];
          // console.log('this data is :')
          // console.log(res);
          this.hideLoading(); // 隐藏加载弹窗
        }


      }).catch(err => {
        // console.log('this data is :')
        console.log(err);
        this.hideLoading(); // 隐藏加载弹窗
        console.error(err); // 处理错误
      });
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      if (this.role1 === 1) {
      this.load()
        // console.log('this begin load')
        this.isA = true

      }else {
        this.load2()

        this.isA = null

      }
    },
    handleSelectionChange(rows){
      this.ids = rows.map(item => item.id);
    },

    reset(){
      this.form={};
      this.form4={};
      this.form5={};
      this.startDate='';   // 开始日期
      this.endDate='';    // 结束日期
      this.pushStats=[];
      this.username='';
      this.tp=0;
      this.tt=0;
      this.loadprojects();
      this.loadPushStats0();
      this.isB=false;
      this.isD=false;
    },

  }
}
</script>

<style scoped>
.w-e-text {
  height: 100% !important; /* 确保占满父容器的高度 */
  min-height: 100% !important; /* 确保最小高度占满父容器 */
}
.w-e-text-container {
  overflow: hidden;
  height: 600px !important;
  min-height: 600px !important; /* 确保最小高度也调整 */
}
.form-container {
  //max-width: 1000px;
  //margin: 0 auto;
}


.ellipsis-text {
  display: block;
  white-space: nowrap;      /* 不换行 */
  overflow: hidden;         /* 超出部分隐藏 */
  text-overflow: ellipsis;  /* 超出部分显示省略号 */
  max-width: 130px;         /* 设置最大宽度 */
}
.el-input__inner{
  width: 80% !important;
}
.form-item-wide { width: 80% !important; /* 使用 !important 来覆盖默认样式 */ }
.el-table .el-table__row {
  height: 50px!important;/* 设置你想要的行高 */
  line-height: 50px!important; /* 垂直居中 */
}
.yh{
  margin-left: 5px !important;
}
.form-row {
  display: flex;
  justify-content: space-between; /* 让表单项均匀分布 */
  margin-bottom: 10px; /* 行间距 */
}
.form-item {
  flex: 1; /* 让表单项均分剩余空间 */
  margin-right: 10px; /* 右边距 */
}
.form-item:last-child {
  margin-right: 0; /* 最后一个项不需要右边距 */
}


</style>
